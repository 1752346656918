<!-- eslint-disable no-unneeded-ternary -->
<template>
  <b-overlay :show="loading">
    <b-container v-if="contract && !loading">
      <b-form
        v-if="!+contract.id"
        @submit.prevent="sign()"
        :validated="true"
        ref="contract"
      >
        <b-card
          bg-variant="light"
          class="text--black"
          no-body
        >
          <b-card-img
            alt=""
            src="https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/16801/ck/images/Streamlined%20Logo%20Horizontal%20Black%20and%20Red.png"
            width="100%"
          ></b-card-img>

          <b-card-body v-if="contract.formdata.how_will_you_be_paying_for_your_sbs_services === 'ACH'">
            <b-form-group
              label="Billing Street Address:"
              label-for="street"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-form-input
                v-model="form.address"
                id="street"
                placeholder="Street Address"
                size="sm"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="City:"
              label-for="city"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-row no-gutters>
                <b-col>
                  <b-form-input
                    v-model="form.city"
                    id="city"
                    placeholder="City"
                    size="sm"
                    required
                  ></b-form-input>
                </b-col>
                <b-col cols="auto" class="form-inline">
                  <label for="state" class="mr-2 ml-4">State:</label>
                  <b-form-input
                    v-model="form.state"
                    id="state"
                    placeholder="State"
                    size="sm"
                    required
                    max-length="2"
                  ></b-form-input>
                </b-col>
                <b-col cols="auto" class="form-inline">
                  <label for="zipcode" class="mr-2 ml-4">Zip Code:</label>
                  <b-form-input
                    v-model="form.zipcode"
                    id="zipcode"
                    placeholder="Zip Code"
                    size="sm"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label="Bank Name:"
              label-for="bankname"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-form-input
                v-model="form.name"
                id="bankname"
                placeholder="Bank Name"
                size="sm"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Account Type:"
              label-for="accounttype"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-form-select
                v-model="form.account_type"
                id="accounttype"
                size="sm"
                required
              >
                <option disabled :value="null">Select Account Type</option>
                <option value="Checking">Checking</option>
                <option value="Savings">Savings</option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Account Number:"
              label-for="accountnumber"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
            <b-form-input
                v-model="form.number"
                id="accountnumber"
                placeholder="Account Number"
                size="sm"
                :class="{'input-valid': accountNumberValid, 'input-invalid': accountNumberValid === false}"
                :state="accountNumberValid"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                Account number must be between 9 and 18 digits.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Routing Number:"
              label-for="routingnumber"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
            <b-form-input
                v-model="form.routing_number"
                id="routingnumber"
                placeholder="Routing Number"
                size="sm"
                :class="{'input-valid': routingNumberValid, 'input-invalid': routingNumberValid === false}"
                :state="routingNumberValid"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                Must be 9 numbers long.
              </b-form-invalid-feedback>
            </b-form-group>

          </b-card-body>

          <b-card-body v-if="contract.formdata.how_will_you_be_paying_for_your_sbs_services === 'Credit Card'">
            <b-form-group
              label="Cardholder Name:"
              label-for="signer"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-form-input
                v-model="form.ccname"
                id="signer"
                placeholder="Name on Credit Card"
                size="sm"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Billing Street Address:"
              label-for="street"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-form-input
                v-model="form.address"
                id="street"
                placeholder="Street Address"
                size="sm"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="City:"
              label-for="city"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-row no-gutters>
                <b-col>
                  <b-form-input
                    v-model="form.city"
                    id="location"
                    placeholder="City"
                    size="sm"
                    required
                  ></b-form-input>
                </b-col>
                <b-col
                  cols="auto"
                  class="form-inline"
                >
                  <label
                    for="state"
                    class="mr-2 ml-4"
                  >State:</label>
                  <b-form-input
                    v-model="form.state"
                    id="state"
                    placeholder="State"
                    size="sm"
                    required
                    max-length="2"
                  ></b-form-input>
                </b-col>
                <b-col
                  cols="auto"
                  class="form-inline"
                >
                  <label
                    for="zipcode"
                    class="mr-2 ml-4"
                  >Zip Code:</label>
                  <b-form-input
                    v-model="form.zipcode"
                    id="zipcode"
                    placeholder="Zip Code"
                    size="sm"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label="Credit Card Type:"
              label-for="cctype"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-form-select
                v-model="form.cctype"
                id="cctype"
                size="sm"
                required
              >
                <option :value="null">Select Card Type</option>
                <option value="Visa">Visa</option>
                <option value="MasterCard">MasterCard</option>
                <option value="Discover">Discover</option>
                <option value="AmEx">AmEx</option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Credit Card Number:"
              label-for="ccnumber"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-form-input
                v-model="form.ccnumber"
                id="ccnumber"
                size="sm"
                placeholder="Credit Card Number"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Expiration Date:"
              label-for="ccexpmonth"
              label-cols="4"
              label-cols-sm="3"
              label-align="right"
              class="mb-1"
            >
              <b-row no-gutters>
                <b-col
                  cols="auto"
                  class="form-inline"
                >
                  <b-form-select
                    v-model="form.ccexpmonth"
                    id="ccexpmonth"
                    size="sm"
                    required
                    class="mr-2"
                  >
                    <option :value="null">MM</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </b-form-select>
                  /
                  <b-form-select
                    v-model="form.ccexpyear"
                    id="ccexpyear"
                    size="sm"
                    required
                    :options="years"
                    class="ml-2"
                  >
                  </b-form-select>
                </b-col>

                <b-col
                  cols="auto"
                  class="form-inline"
                >
                  <label
                    class="control-label mr-2 ml-4"
                    for="cccode"
                  >Security Code:</label>
                  <b-form-input
                    v-model="form.cccode"
                    id="cccode"
                    placeholder="CVV security code"
                    size="sm"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>

            </b-form-group>

          </b-card-body>

          <b-card-body
            class="text-left py-0"
            v-html="invoice"
          >
          </b-card-body>

          <b-card-body class="text-left pt-0">
            <b-form-group
              label="Signed:"
              label-for="signature"
              label-cols="4"
              label-cols-sm="3"
              label-cols-md="2"
              label-align="right"
              class="mb-1"
            >
              <b-form-input
                id="signature"
                v-model="form.signature"
                type="text"
                placeholder="Your Signature"
                required
                style="max-width: 300px;"
                size="sm"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Dated:"
              label-for="signature"
              label-cols="4"
              label-cols-sm="3"
              label-cols-md="2"
              label-align="right"
              class="mb-1"
            >
              <b-form-input
                plaintext
                :value="contract.formdata.signaturedate"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Name:"
              label-for="signature"
              label-cols="4"
              label-cols-sm="3"
              label-cols-md="2"
              label-align="right"
              class="mb-1"
            >
              <b-form-input
                plaintext
                :value="contract.formdata.signer"
              ></b-form-input>
            </b-form-group>

            <b-form-row class="mb-5">
              <b-col offset="4" offset-sm="3" offset-md="2">
                <b-button
                  type="button"
                  @click.prevent="scrollToElementById('template_1_initials')"
                  variant="info"
                  :disabled="loading"
                >Next</b-button>
              </b-col>
            </b-form-row>
          </b-card-body>
        </b-card>
      </b-form>

      <b-card
        bg-variant="light"
        no-body
        class="mb-3 text--black"
      >
        <b-card-img
          v-if="contract.id"
          alt=""
          src="https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/16801/ck/images/Streamlined%20Logo%20Horizontal%20Black%20and%20Red.png"
          width="100%"
        ></b-card-img>
        <div
          v-if="contract.id"
          class="card-body"
        >
          <h1>Signature Submitted</h1>
          <p>Your contract has been signed and submitted successfully. You may download a PDF copy for your records below.</p>
          <b-button
            v-if="contract.downloadurl"
            :href="contract.downloadurl"
            variant="primary"
          >Download PDF</b-button>
        </div>
        <div v-else>
          <div v-html="html"></div>
          <b-form-row class="mb-5">
            <b-col>
              <b-button
                type="button"
                @click.prevent="sign"
                variant="success"
                :disabled="isDisabled()"
              >Sign Contract</b-button>
            </b-col>
          </b-form-row>
        </div>
      </b-card>
    </b-container>
  </b-overlay>
</template>
<script>
import ContractService from '@/services/ContractService'

export default {
  name: 'Contract',

  props: {
    hash: String
  },

  data () {
    return {
      loading: false,
      form: {
        cctype: null,
        ccname: null,
        address: null,
        city: null,
        state: null,
        zipcode: null,
        ccnumber: null,
        ccexpmonth: null,
        ccexpyear: null,
        cccode: null,
        signature: null,
        initials: null,
        account_type: null,
        number: null,
        routing_number: null,
        name: null
      },
      contract: null,
      invoice: null,
      html: null,
      templateCount: 4,
      initialSkipTimer: null,
      years: [{ value: null, text: 'YYYY' }],
      formValidation: {
        accountNumberState: null,
        routingNumberState: null
      }
    }
  },

  computed: {
    accountNumberValid () {
      // Adjust regex as needed
      const vari = /^\d{9,18}$/.test(this.form.number)
      return vari
    },
    routingNumberValid () {
      // Adjust regex as needed
      const vari = /^\d{9}$/.test(this.form.routing_number)
      return vari
    }
  },

  mounted () {
    const d = new Date()
    let year = +d.getFullYear()
    while (year <= +d.getFullYear() + 20) {
      this.years.push({ value: +year, text: year })
      year++
    }
    this.init()
  },

  // fires when dom changes, i.e. the html variable is updated by the ajax call
  updated () {
    this.$nextTick(() => {
      this.initializeInitialInputs()
    })
  },

  methods: {
    init () {
      this.loading = true
      ContractService.get(this.hash).then((response) => {
        console.log(response)
        this.contract = response.data.info.contract
        this.html = response.data.info.html
        this.invoice = response.data.info.invoice
        this.form.ccname = this.contract.formdata.signer
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    validateCreditCardNumber () {
      const ccNum = this.form.ccnumber
      const cardType = this.form.cctype

      // Regular expressions for different card types
      const cardPatterns = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MasterCard: /^5[1-5][0-9]{14}$/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        AmEx: /^3[47][0-9]{13}$/
      }

      if (cardType in cardPatterns) {
        return cardPatterns[cardType].test(ccNum)
      }

      return false
    },

    validateExpirationDate () {
      const month = parseInt(this.form.ccexpmonth, 10)
      const year = parseInt(this.form.ccexpyear, 10)
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth() + 1 // JS months are 0-indexed
      const currentYear = currentDate.getFullYear()

      if (year < currentYear || (year === currentYear && month < currentMonth)) {
        return false
      }

      return month >= 1 && month <= 12
    },

    validateCVV () {
      const cvv = this.form.cccode
      const cardType = this.form.cctype

      // Different card types have different CVV lengths
      const cvvLengths = {
        Visa: 3,
        MasterCard: 3,
        Discover: 3,
        AmEx: 4
      }

      return cvv.length === (cvvLengths[cardType] || 3)
    },

    isDisabled () {
      if (this.contract.formdata.how_will_you_be_paying_for_your_sbs_services === 'Credit Card') {
        if (!this.form.cccode ||
           !this.form.ccexpmonth ||
           !this.form.ccexpyear ||
           !this.form.ccname ||
           !this.form.ccnumber ||
           !this.form.cctype ||
           !this.form.address) {
          return true
        }
      } else if (this.contract.formdata.how_will_you_be_paying_for_your_sbs_services === 'ACH') {
        if (!this.form.name ||
           !this.form.account_type ||
           !this.form.number ||
           !this.form.routing_number ||
           !this.form.address) {
          return true
        }
      }
      return false
    },

    initializeInitialInputs () {
      for (let i = 1; i <= this.templateCount; i++) {
        const eleid = 'template_' + i + '_initials'
        const element = document.getElementById(eleid)
        if (!element) {
          return false
        }
        element.addEventListener('input', (event) => {
          this.validateInitialInputs(event.target, i)
        })
        this.validateInitialInputs(element, i)
      }
    },

    validateInitialInputs (element, index) {
      if (element.validity.valid) {
        element.classList.remove('is-invalid')
        element.classList.add('is-valid')
        if (this.initialSkipTimer) {
          clearTimeout(this.initialSkipTimer)
        }
        this.initialSkipTimer = setTimeout(() => {
          this.scrollToElementById('template_' + (index + 1) + '_initials')
        }, 1000)
        return true
      } else {
        element.classList.remove('is-valid')
        element.classList.add('is-invalid')
        return false
      }
    },

    hasInitialedEverything () {
      let unInitialed = false
      for (let i = 1; i <= this.templateCount; i++) {
        const eleid = 'template_' + i + '_initials'
        const element = document.getElementById(eleid)
        if (!element) {
          break
        }
        const r = this.validateInitialInputs(element, i)
        if (!r) {
          unInitialed = eleid
          break
        }
      }
      return unInitialed
    },

    // returns the value from the first initial box
    getInitials () {
      const el = document.getElementById('template_1_initials')
      return (el) ? el.value : null
    },

    scrollToElementById (id) {
      const e = document.getElementById(id)
      if (e) {
        e.scrollIntoView()
        e.focus()
      }
    },

    sign () {
      /* const valid = this.$refs.contract.checkValidity()
      if (!valid || this.loading) {
        document.body.scrollTop = document.documentElement.scrollTop = 0
        return false
      } */
      if (this.contract.formdata.how_will_you_be_paying_for_your_sbs_services === 'Credit Card') {
        if (!this.validateCreditCardNumber()) {
          this.$aimNotify.error('Invalid credit card number.')
          return false
        }
        if (!this.validateExpirationDate()) {
          this.$aimNotify.error('Invalid expiration date.')
          return false
        }
        if (!this.validateCVV()) {
          this.$aimNotify.error('Invalid CVV.')
          return false
        }
      }

      const unInitialed = this.hasInitialedEverything()
      if (unInitialed !== false) {
        this.scrollToElementById(unInitialed)
        return false
      }

      this.form.initials = this.getInitials()

      this.loading = true
      const data = JSON.parse(JSON.stringify(this.form))
      data.hash = this.hash
      ContractService.sign(data).then((response) => {
        this.$aimNotify.notify(response, 'info', 'Contract Signed', 'Your contract was successfully signed.')
        this.init()
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
/* Add these styles to your component or a global stylesheet */
.input-valid {
  border-color: #28a745 !important; /* Bootstrap success color */
  background-image: none !important;
}

.input-invalid {
  border-color: #dc3545 !important; /* Bootstrap danger color */
  background-image: none !important;
}

.input-valid::after,
.input-invalid::after {
  content: none !important;
}

</style>
