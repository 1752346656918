import axios from 'axios'

const ContractService = {
  async get (hash) {
    return axios({
      url: axios.defaults.baseURL + 'contract/get',
      method: 'POST',
      data: JSON.stringify({ hash: hash })
    })
  },

  async sign (data) {
    return axios({
      url: axios.defaults.baseURL + 'contract/sign',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async download (id) {
    return axios({
      url: axios.defaults.baseURL + 'contract/download/' + +id,
      method: 'GET',
      responseType: 'blob'
    })
  },

  async preview (data) {
    return axios({
      url: axios.defaults.baseURL + 'contract/preview/',
      method: 'POST',
      data: JSON.stringify(data),
      responseType: 'blob'
    })
  }
}

export default ContractService
